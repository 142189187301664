/* ==========================================================================
  Variables liées à la base
  ========================================================================== */

$space-thumbnail :            $thumbnail-caption-padding/2;
$space-thumbnail-invert :     -$space-thumbnail;
$height-img:                  300px;

/* ==========================================================================
  Général
  ========================================================================== */

.breadcrumb {
	margin: 0;
  font-size: 14px;
}

.caddie-etapes {
  display: flex;
  flex-direction: column;
  
  @media(min-width: $screen-sm-min) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media(min-width: $screen-lg-min) {
    flex-direction: row;
    flex-wrap: no-wrap;
  }

  a.btn {
    float: none;
    width: 100%;
    margin: 0 !important;
    border-radius: 0;

    @media(min-width: $screen-sm-min) {
      width: 50%;
    }

    @media(min-width: $screen-md-min) {
      width: 33.333%;
    }

    @media(min-width: $screen-lg-min) {
      width: auto;
      flex: 1;
    }
  }
}

.btn-pdf {
  @extend .btn;
  @extend .btn-danger;
}

// Présentation global des fiche des base et popup.
.fiche-base {
  @extend .thumbnail;
  @extend .clearfix;
  position: relative;
}

.img-fancybox {
  @extend .img-responsive;
  @extend .center-block;
}

/* ==========================================================================
  Fiche résumé
  ========================================================================== */

.fiche-resum {
  @extend .fiche-base;
  @extend .text-center;
  @extend .center-block;
  // padding: 3%;
  padding-bottom: 80px;
  max-width: 430px;
}

.fiche-resum_pict {
  height: $height-img;
  // height: calc(300px + 6%);
  // padding: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: rgba(black, .03);
  // border-bottom: solid 1px rgba(black, .05);

  img {
    max-height: $height-img;
  }
}

.fiche-resum_label {
  position: absolute;
  top: 0;
  right: 0;
  padding: .5rem;
  // background-color: $thumbnail-bg;

  span.label {
    display: inline-block;
    padding: .5rem;
    font-weight: 400;
    border-radius: 0;
  }
}

.fiche-resum_caption {
  padding: 5%;
}

.fiche-resum_titre-1 {
  font-size: $font-size-large;
  color: $headings-color;
}

.fiche-resum_titre-2 {
  font-size: $font-size-base;
  color: $headings-color;
}

.fiche-resum_desc {
  font-size: $font-size-small;
  padding: 3%;
}

.fiche-resum_panel-prix {

  del {
    display: inline-block;
    margin-right: 5%;
  }
}

.fiche-resum_prix-strong {
  font-size: $font-size-large;
  font-weight: 700;
  color: $brand-primary;
  white-space: nowrap;
}

.fiche-resum_bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5%;
}

.btn-fiche-resum {
  @extend .btn;
  @extend .btn-default;
}

/* ==========================================================================
  Fiche détail
  ========================================================================== */

.fiche-detail {
  @extend .fiche-base;
  padding: 2%;
}

.fiche-detail_pict {
  @extend .clearfix;

  >.col-xs-6,
  >.col-xs-12,
  >.col-xs-4 {
    padding: 2%;
  }
}

.fiche-detail_label {
  @extend .fiche-resum_label;
}

.fiche-detail_titre {
  font-size: $font-size-h3;
  margin: 0 0 2rem;
}

.fiche-detail_ref {
  padding-left: 1rem;
  margin-top: $thumbnail-caption-padding;
  border-left: solid 1px rgba($thumbnail-caption-color, .3);
}

.fiche-detail_desc {
  margin-bottom: 2rem;
}

.fiche-detail_panel-prix {
  @extend .fiche-resum_panel-prix;
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: rgba(black, .05);
}

.fiche-detail_btn-container {
  @extend .fiche-detail_desc;
}

.fiche-detail_content-form {
  margin-top: 3rem;
}

.fiche-detail_btn-commander {
  @extend .btn;
  @extend .btn-lg;
  @extend .btn-primary;
}

.fiche-detail_prix-strong {
  @extend .fiche-resum_prix-strong;
}

.fiche-detail_bottom {
  @extend .text-right;
  padding-top: 2%;
}

/* ==========================================================================
  Base "popup"
  ========================================================================== */

.fiche-popup {
  @extend .fiche-base;
  @extend .text-left;
  padding: 2%;
  margin: 0 auto;
  // max-width: 1000px;
  box-shadow: $box-shadow;
}

.fiche-popup_titre {
  @extend .h2;
  @extend .fiche-detail_titre;
}

.fiche-popup_pict {
  @extend .fiche-detail_pict;
}

.fiche-popup_bottom {
  // @extend .caption;
  @extend .text-right;
}

.fiche-popup_description {
  @extend .fiche-detail_desc;
  font-size: $font-size-base;
}

.fiche-popup_list-btn {
  @extend .fiche-detail_list-btn;
}

/* ==========================================================================
  Liens sociaux
  ========================================================================== */

.link-fb {
  font-size: 20px;
  .fa-circle {
    color: #3B579C;
  }
}

.link-tw {
  font-size: 20px;
  .fa-circle {
    color: #1da1f2;
  }
}

.link-pnt {
  font-size: 20px;
  .fa-circle {
    color: #bd081c;
  }
}


/* ==========================================================================
  Base "blog"
  ========================================================================== */

.sauteauxyeux {
  @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
}

/* ==========================================================================
  Mini-caddie
  ========================================================================== */

.panel-mini-caddie {
  @extend .panel;
  @extend .panel-default;
  @extend .hidden-sm;
  @extend .hidden-xs;
  font-size: .8rem;
}

a.panel-mini-caddie_heading {
  @extend .panel-heading;
  @extend .btn-default;
  display: block;
  text-decoration: none;
  font-size: $font-size-h4;
}

.panel-code-promo {
  @extend .panel-mini-caddie;
}

a.panel-code-promo_heading {
  @extend .panel-mini-caddie_heading;
}

/* ==========================================================================
  Mini-caddie footer
  ========================================================================== */

.panel-mini-caddie-footer {
  @extend .panel;
}

.mini-caddie-quant {
  display: inline-block;
  padding-left: 10px;
}

/* ==========================================================================
  Compte client
  ========================================================================== */

.compte-client_nav {
  margin-bottom: 3%;
}

.compte-client_nav_list {
  @extend .list-inline;
  @extend .text-center;
}

/* ==========================================================================
  Testimonial
  ========================================================================== */

.slider-testimonial {
  margin: 5%;
}

.testimonial_blockquote {
  width: 90rem;
  max-width: 100%;
  padding: 80px 50px 30px;
  margin: 50px auto;
  border:none;
  background-color: white;
  position: relative;

  &::before {
    content: '\f10d';
    font-family: 'fontAwesome';
    color: $brand-primary;
    font-style: normal;
    position: absolute;
    top: -40px;
    left: 50%;
    width: 80px;
    height: 80px;
    margin-left: -40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }

  .footer {
    font-family: $font-family-sans-serif;
  }
}

.testimonial_titre {
  margin-bottom: 20px;
}

.testimonial_description {
  font-family: $font-family-serif;
  font-style: italic;
}

.testimonial_signature {
  padding-top: 20px;
  margin-top: 30px;
  display: inline-block;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 100px;
    margin-left: -50px;
    height: 1px;
    background-color: $gray-lighter;
  }
}

/* ==========================================================================
  Collapse FAQ
  ========================================================================== */

.panel-faq {
  @extend .center-block;
  max-width: 1000px;
}

.panel-default-collapse {
  @extend .panel-default;
  margin: 1% auto !important;
  max-width: 1000px;
}

.panel-heading-collapse {
  @extend .panel-heading;
  padding: 0;
}

.panel-title-collapse {
  @extend .panel-title;

  >.btn-collapse {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px;
    // background-color: $gray-lighter;
    // color: white;
    font-weight: 700;

    &:hover,
    &:active,
    &:focus {
      background-color: darken($gray-lighter, 5%);
    }

    @media(min-width: $screen-sm-min) {
      flex-direction: row;
      justify-content: space-between;
    }

    >.collapse-arrow {
      display: inline-block;
      text-transform: none;
      font-weight: 400;
      padding-top: 10px;

      @media(min-width: $screen-sm-min) {
        padding: 0;
      }
    }
  }
}

.panel-body-collapse {
  @extend .panel-body;
}

.videos-grid {

  @media(min-width: $screen-md-min) {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 2% 0;
    margin: 0 -15px;
  }
}

.video-grid_figure {
  @extend .text-center;
  padding: 15px;
  width: 100%;

  >a {
    display: flex;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    background-color: black;

    >img {
      @extend .img-responsive;
      @extend .center-block;
      border-radius: 5px;
      opacity: .8;
      transition: opacity ease .5s;
    }

    &::after {
      content: '\f04b';
      font-family: 'fontAwesome';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 60px;
      height: 60px;
      margin: -30px 0 0 -30px;
      border-radius: 50%;
      background-color: rgba($brand-primary, .8);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      
      >img {
        opacity: 1;
      }
    }
  }

  @media(min-width: $screen-md-min) {
    width: 50%;
  }

  @media(min-width: $screen-lg-min) {
    width: 33.333%;
  }
}