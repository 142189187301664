/* ==========================================================================
	 header-basic
	 ========================================================================== */

.header-basic {
	position: relative;
	background: linear-gradient(rgba($body-bg, .8), rgba($body-bg, .5));;
	
	.content-header {
		@extend .container-max;
		padding: 3rem;
	}
	
	.logo-header {
		
		>img {
			@extend .img-responsive;
			display: inline-block;
			width: 30rem;
		}
	}
}